import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { useGetWeightAlertQuery } from "../../../store/apis/WeightAlertApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";

export default function WeightAlert() {
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);

  const {
    data: weightAlerts,
    isFetching: loading,
    isError: hasError,
  } = useGetWeightAlertQuery(
    {
      facilityId: facilityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const residentsColumns = [
    {
      field: "_1monthWeightAlert",
      headerName: "One Month % Change",
      flex: 1,
    },
    {
      field: "_3monthWeightAlert",
      headerName: "Three Month % Change",
      flex: 1,
    },
    {
      field: "_6monthWeightAlert",
      headerName: "Six Month % Change",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <BaseActionBox
            hasEdit={true}
            editProps={{
              state: {
                title: "Edit WeightAlert",
                data: param.row,
              },
              to: `edit?id=${facilityId}`,
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1} paddingRight={1} marginTop={1}>
        <Grid item sm={12} md={8} lg={8} xl={8}>
          <Typography variant="h6" component="h2">
            {`Weight Alerts for ${facilityName}`}
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={weightAlerts}
            getRowId={(row) => row.facilityId + row._3monthWeightAlert}
            columns={residentsColumns}
            loading={loading}
            error={hasError}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "58vh",
              lg: "63vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
