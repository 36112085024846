import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";
import {
  INVALID_US_PHONE_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const addDoctor_ScreenNames = [
  {
    id: 1,
    screenName: false,
    bgColor: theme.palette.primary.main,
    fontWeight: "600",
    color: DMStyles.White,
    marginBottom: "5px",
  },
  {
    id: 2,
    screenName: "Add Doctor",
    bgColor: theme.palette.orange.main,
    fontWeight: "500",
    color: DMStyles.White,
    marginBottom: "0px",
  },
];

export const ADD_DOCTOR_FIELDS = [
  {
    props: {
      name: "office",
      id: "office",
      label: "Office Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Office Name"),
    },
    size: 10,
  },

  {
    props: {
      name: "doctor",
      id: "doctor",
      label: "Doctor Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Doctor Name"),
    },
    size: 10,
  },
  {
    props: {
      name: "faxNumber",
      id: "faxNumber",
      label: "To Fax Number",
    },
    validationProps: {
      required: REQUIRED_ERROR("To Fax Number"),
      pattern: INVALID_US_PHONE_VALIDATION("Fax Number"),

      // pattern: NUMBER_WITH_HYPHEN_VALIDATION(),
    },
    size: 10,
  },

  {
    props: {
      name: "isActive",
      id: "isActive",
      label: "Active",
    },
    size: 10,
    type: FORM_TYPES.Checkbox,
  },
];
