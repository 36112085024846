import React from "react";
import { useGetResidentWeightQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";

export default function ResidentWeightHistory() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: WeightHistory,
    isLoading,
    isError,
  } = useGetResidentWeightQuery(residentId, { skip: !residentId });

  const residentWeights = WeightHistory;

  const WeightColumns = [
    {
      field: "weightValue",
      minWidth: 50,
      flex: 1,
      headerName: "Weight",
    },
    {
      field: "note",
      minWidth: 50,
      flex: 1,
      headerName: "Note",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "weightDate",
      minWidth: 50,
      flex: 1,
      headerName: "Date",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueGetter: (params) => dayjs(params.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={WeightHistory}
            hasEdit={true}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.weightValue,
              apiPath: "deleteResidentWeight",
            }}
            editProps={{
              To: `editWeightHistory?id=${residentId}`,
              title: "WeightHistory",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} md={4} lg={3} xl={3} marginTop={1}>
          <BaseButton
            text={"Add Weight Measurement"}
            To={`addWeightHistory?id=${residentId}`}
            state={{
              name: WeightHistory?.residentName,
            }}
            endIcon="post_add"
            marginLeft="0px !important"
            sx={{
              marginY: "0px !important",
            }}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={residentWeights}
            columns={WeightColumns}
            error={isError}
            loading={isLoading}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "55vh",
              lg: "62vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
