import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Tooltip, Box } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import "./CommunitySetup.css";

import {
  useGetFacilityListsQuery,
  useSearchFacilitiesQuery,
} from "../../../../../store/apis/FacilityApis";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSnackbar } from "notistack";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { Check, Close, Download, Inventory2 } from "@mui/icons-material";

export default function CommunitySetup() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const SEARCH_PARAM = "search";
  const SHOW_ARCHIVED_PARAM = "showArchived";
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const vendorId = searchParams.get("vendorId") || "";
  const purchasingGroupId = searchParams.get("purchasingGroupId") || "";
  const corporateGroupId = searchParams.get("corporateGroupId") || "";
  const [showArchived, setShowArchived] = useState(
    searchParams.get(SHOW_ARCHIVED_PARAM) || false
  );
  const {
    data: communities,
    isFetching: loading,
    isError: hasError,
  } = useSearchFacilitiesQuery({
    searchTerm: debouncedValue,
    vendorId: vendorId,
    purchasingGroupId: purchasingGroupId,
    corporateGroupId: corporateGroupId,
    showArchived: showArchived,
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const communityData = communities?.items || [];
  const [fetchReport, setFetchReport] = useState(false);
  const {
    data: reportData,
    isFetching: reportFetching,
    isSuccess: reportSuccess,
  } = useGetFacilityListsQuery(undefined, {
    skip: !fetchReport,
  });
  useEffect(() => {
    if (reportSuccess) {
      DownloadLinkHelper(reportData?.data);
      enqueueSnackbar(`${reportData?.messages[0]}`, {
        variant: "success",
      });
      setFetchReport(true);
    }
  }, [reportData?.data]);

  const communityColumns = [
    {
      field: "name",
      headerName: "Community Name",
      renderCell: (params) => {
        return (
          <FlexBox justifyContent={"start"}>
            <Link
              to={`/admin/communities/communitydetail/details?id=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>
              {params.row["name"]}
            </Link>
            {params?.row?.isArchived && (
              <Tooltip title="Archived">
                <Inventory2 />
              </Tooltip>
            )}
          </FlexBox>
        );
      },
      flex: 2,
      minWidth: 300,
    },
    {
      field: "corporateGroup",
      sortable: true,
      disableColumnMenu: false,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Corporate Group",
      renderCell: (params) => {
        return <>{params?.row?.corporateGroup ?? "None"}</>;
      },
    },
    {
      field: "state",
      sortable: true,
      disableColumnMenu: false,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Location",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.city
              ? `${params?.row?.city}, ${params?.row?.state}`
              : "None"}
          </>
        );
      },
    },
    {
      field: "vendor",
      sortable: true,
      disableColumnMenu: false,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Vendor",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return <>{params?.row?.vendor ?? "None"}</>;
      },
    },
    {
      field: "clientSuccessUser",
      sortable: true,
      disableColumnMenu: false,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "CS Specialist",
    },
    {
      field: "dietitian",
      sortable: true,
      disableColumnMenu: false,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "RD Assigned",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.dietitianCount > 0 &&
              `[${params?.row?.dietitianCount}] ${params?.row?.dietitian}`}
          </>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            hasView={true}
            hasEdit={true}
            index={params.row.id}
            data={communityData}
            editProps={{
              To: `/admin/communities/communitySetup/addcommunity?id=${params.row.id}`,
            }}
            viewProps={{
              To: `/admin/communities/communitydetail/details?id=${params.row.id}`,
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox flexWrap={{ xs: "wrap", md: "nowrap" }} my="8px">
        <Box>
          <BaseSearch
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isArchived={true}
            showArchived={showArchived}
            corporateGroupId={corporateGroupId}
          />
        </Box>
        <FlexBox justifyContent="space-between">
          <FlexBox justifyContent="space-between">
            <BaseButton
              To="addcommunity"
              variant="contained"
              endIcon={"group_add"}
              text={"Add Community"}
              marginLeft={"0px !important"}
            />
            <BaseButton
              startIcon={showArchived ? <Check /> : <Close />}
              onClick={() => {
                setShowArchived(!showArchived);
                setSearchParams({
                  [SEARCH_PARAM]: debouncedValue,
                  [SHOW_ARCHIVED_PARAM]: !showArchived,
                });
              }}
              text={"Show Archived"}
            />
          </FlexBox>
          <Box>
            <Button
              id="report-button"
              color={"error"}
              disableElevation
              onClick={() => {
                if (reportSuccess) {
                  DownloadLinkHelper(reportData?.data);
                  enqueueSnackbar(`${reportData?.messages[0]}`, {
                    variant: "success",
                  });
                }
                setFetchReport(true);
              }}
              variant={"contained"}
              endIcon={<Download />}
              startIcon={
                reportFetching && (
                  <CircularProgress color="warning" size={"1rem"} />
                )
              }>
              Reports
            </Button>
          </Box>
        </FlexBox>
      </FlexBox>

      <BaseDataGrid
        rows={communityData}
        rowCount={communities?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={communityColumns}
        error={hasError}
        loading={loading}
        autoHeight={true}
        overflow={"none"}
        getRowHeight={() => "auto"}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}
